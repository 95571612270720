import { createStore  } from "vuex";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  // initial state
  state: {
    all: [],
    url: 'https://gonlopsa.mecasite.com/api/',
    // url: 'http://localhost:5100/api/',
    token: null,
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setToken(state, token) {
      localStorage.setItem('tk', token)
      state.token = token
    },
  },
  actions: {
    async login(context, { data }) {
      try {
        const options = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }
        let req = await fetch(context.state.url + 'usuarios/autenticate', options)
        let res = await req.json()
        //console.log(res)
        context.state.token = res.token
        localStorage.setItem('tk', res.token)
        localStorage.setItem('user', JSON.stringify(res.data))
        if (res.error) {
          //this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
          alert(res.error)
          return
        } else {
          //console.log('login')
          return res
        }
      } catch (error) {
        console.error('error', error)
        return 0
      }
    },
    async get(context, { path }) {
      try {
        let token = localStorage.getItem('tk')
        const options = {
          method: 'GET',
          headers: {
            'access-token': token,
            'Content-Type': 'application/json',
          },
        }
        let req = await fetch(context.state.url + path, options)
        let res = await req.json()
        if (res.mensaje == 'Token inválida') {
          //router.push('/')
        }
        if (res.error) {
          console.error(res.error)
          alert(res.mensaje)
        } else {
          return res
        }
      } catch (error) {
        console.error('error', error)
        return error
      }
    },
    async post(context, { path, data }) {
      try {
        let token = localStorage.getItem('tk')
        const options = {
          method: 'POST',
          headers: {
            'access-token': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
        let req = await fetch(context.state.url + path, options)
        let res = await req.json()
        if (res.mensaje == 'Token inválida') {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error)
        } else {
          return res
        }
      } catch (error) {
        console.error('error---------->', error)
        return error
      }
    },
    async put(context, { path, data }) {
      try {
        let token = localStorage.getItem('tk')
        const options = {
          method: 'PUT',
          headers: {
            'access-token': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
        let req = await fetch(context.state.url + path, options)
        let res = await req.json()
        if (res.mensaje == 'Token inválida') {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error)
        } else {
          return res
        }
      } catch (error) {
        console.error('error---------->', error)
        return error
      }
    },
    async delete(context, { path }) {
      try {
        let token = localStorage.getItem('tk')
        const options = {
          method: 'DELETE',
          headers: {
            'access-token': token,
            'Content-Type': 'application/json',
          },
        }
        let req = await fetch(context.state.url + path, options)
        //console.log(req)
        let res = await req.json()
        if (res.mensaje == 'Token inválida') {
          //router.push('/')
        }
        if (req.status != 200) {
          alert(req.error)
        } else {
          return req
        }
      } catch (error) {
        console.error('error---------->', error)
        return error
      }
    },
  },












  strict: debug,
});
