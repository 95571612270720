<template>
  <!-- <div class="hello">
    <h1>{{ msg }}</h1>
  </div> -->
  <main class="page payment-page">
    <section class="payment-form dark">
      <div class="container">
        <div class="block-heading">
          <h2>Checkout Gonlop parts</h2>
          <!-- <button @click="generateRandomString(55)">gen code</button><br><br>
          <button @click="getState">Test getdata</button><br><br>
          <button v-on:click="sale">TEST SALE</button><br><br>

          {{codgen}}
          {{dataPayment}} -->
          <!-- {{dataPayment.productos}} -->
          <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo.</p> -->
        </div>
        <form v-if="dataPayment.length > 0">
          <h3 class="title">Checkout</h3>
          <div v-for="it of dataPayment[0].productos" :key="it.id" class="products">
            <div class="item">
              <span class="price">C$ {{ it.total.toFixed(2) }}</span>
              <p class="item-name">{{ it.nombre }}</p>
              <!-- <p class="item-description">Lorem ipsum dolor sit amet</p> -->
            </div>
            <!-- <div class="item">
              <span class="price">$120</span>
              <p class="item-name">Product 2</p>
              <p class="item-description">Lorem ipsum dolor sit amet</p>
            </div> -->
            <!-- <div class="total">Total<span class="price">$320</span></div> -->
          </div>
          <div class="row mr-2">
            <div class="col-8 text-right">Sub-total:</div>
            <div class="col-4 text-right">C$ {{ dataPayment[0].subtotal.toFixed(2) }}</div>
          </div>
          <div class="row mr-2">
            <div class="col-8 text-right">Descuento:</div>
            <div class="col-4 text-right">C$ {{ dataPayment[0].descuento.toFixed(2) }}</div>
          </div>
          <div class="row mr-2">
            <div class="col-8 text-right">Iva:</div>
            <div class="col-4 text-right">C$ {{ dataPayment[0].iva.toFixed(2) }}</div>
          </div>
          <div class="row mr-2">
            <div class="col-8 text-right">Total:</div>
            <div class="col-4 text-right">C$ {{ dataPayment[0].total.toFixed(2) }}</div>
          </div>

          <form @submit.prevent="sale()">
            <div class="card-details">
              <h3 class="title">Tarjeta de crédito</h3>
              <div class="row">
                <div class="form-group col-sm-7">
                  <label for="card-holder">Nombre en tarjeta de crédito</label>
                  <input required v-model="dataPayment[0].tar_nombre" id="card-holder" type="text" class="form-control" placeholder="Card Holder" aria-label="Card Holder" aria-describedby="basic-addon1" />
                </div>
                <div class="form-group col-sm-5">
                  <label for="">vencimiento</label>
                  <div class="input-group expiration-date">
                    <!-- {{dataPayment[0].vencimiento.split(vencimiento, '/')}} -->
                    <input required v-model="vencimientoM" type="text" class="form-control text-right" placeholder="MM" aria-label="MM" aria-describedby="basic-addon1" />
                    <span class="date-separator">/</span>
                    <input required v-model="vencimientoY" type="text" class="form-control" placeholder="YY" aria-label="YY" aria-describedby="basic-addon1" />
                  </div>
                </div>
                <div class="form-group col-sm-8">
                  <label for="card-number">Número de Tarjeta</label>
                  <div class="row">
                    <div class="col-12">
                      <input required id="card-number" v-model="tarjetac" type="text" class="form-control" placeholder="Card Number" aria-label="Card Holder" aria-describedby="basic-addon1" />
                    </div>
                    <!--<div class="col-4">
            <input id="card-number" v-model="tarjetacF" type="text" class="form-control" placeholder="XXXX" aria-label="Card Holder" aria-describedby="basic-addon1" />
          </div> -->
                  </div>
                </div>
                <div class="form-group col-sm-4">
                  <label for="cvc">CVC</label>
                  <input required v-model="CVC" id="cvc" type="text" class="form-control" placeholder="CVC" aria-label="Card Holder" aria-describedby="basic-addon1" />
                </div>
                <div class="form-group col-sm-12">
                  <button  type="submit" class="btn btn-primary btn-block">Procesar</button>
                  {{ message }}
                </div>
              </div>
            </div>
          </form>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import Settings from "@pixelpay/sdk-core/lib/models/Settings";
import Card from "@pixelpay/sdk-core/lib/models/Card";
import Billing from "@pixelpay/sdk-core/lib/models/Billing";
import Item from "@pixelpay/sdk-core/lib/models/Item";
import Order from "@pixelpay/sdk-core/lib/models/Order";
import SaleTransaction from "@pixelpay/sdk-core/lib/requests/SaleTransaction";
import Transaction from "@pixelpay/sdk-core/lib/services/Transaction";
import TransactionResult from "@pixelpay/sdk-core/lib/entities/TransactionResult";

export default {
  props: {
    msg: String,
  },
  data() {
    return {
      id: 0,
      codgen: "",
      dataPayment: {},
      vencimientoM: null,
      vencimientoY: null,
      tarjetac: "",
      tarjetacF: "",
      CVC: "",
      message: "",
    };
  },
  async mounted() {
    this.codgen = this.$route.params.id || 0;
    //alert(this.id)
    await this.getDataPayment();
  },
  methods: {
    async pay() {
      // if (this.CVC == "" || this.CVC == null) {
      //   alert("Falta CVC");
      //   return;
      // }
      // if (this.tar_nombre == "" || this.tar_nombre == null) {
      //   alert("Falta Nombre en tarjeta de crédito");
      //   return;
      // }
      // if (this.tarjetac == "" || this.tarjetac == null) {
      //   alert("Falta Número de tarjeta de crédito");
      //   return;
      // }
      // if (this.tarjetac == "" || this.tarjetac == null) {
      //   alert("Falta Número de tarjeta de crédito");
      //   return;
      // }
      ////console.log(JSON.stringify(this.dataPayment[0]));
      let data = { estado: 1, cliente_id: this.dataPayment[0].cliente_id };
      ////console.log(data);
      await this.$store.dispatch("put", { path: "pedidos_clientes/payment/" + this.dataPayment[0].id, data });
      ////console.log(dataPayment);
      this.$router.push("/success");
    },
    async generateRandomString(num) {
      // const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz*$!";
      let result1 = "";
      const charactersLength = characters.length;
      for (let i = 0; i < num; i++) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      //console.log(result1);
      this.codgen = result1;
      return await result1;
    },
    procesar() {
      //console.log("helou");
      //alert(this.$store.state.count)
    },
    async getDataPayment() {
      let dataPayment = await this.$store.dispatch("get", { path: "pedidos_clientes/getByCodePayment/" + this.codgen });
      this.dataPayment = dataPayment;
      //console.log("------", this.dataPayment);
      let vencimiento = dataPayment[0].vencimiento;
      //console.log("------", vencimiento);
      // this.vencimiento = vencimiento.split(vencimiento, '/')
      this.vencimientoM = vencimiento.substr(0, 2);
      this.vencimientoY = vencimiento.substr(3, 2);
      this.tarjetac = dataPayment[0].numero; //.substr(0,dataPayment[0].numero.length-4)
      this.CVC = dataPayment[0].cvv;
      //,vencimiento.substr(3,2)
      //console.log("------", this.vencimientoM);
    },

    sale() {
      const settings = new Settings();
      settings.setupEndpoint("https://ni.ficoposonline.com");
      settings.setupCredentials("FN4067011851", "5cddc4f18755a0a575f6fc8c0c8d2181");

      const card = new Card();
      card.number = this.tarjetac;
      card.cvv2 = this.CVC;
      card.expire_month = this.vencimientoM;
      card.expire_year = "20" + this.vencimientoY;
      card.cardholder = this.dataPayment[0].tar_nombre;

      const billing = new Billing();
      billing.address = "Managua";
      billing.country = "NI";
      billing.state = "NI-MN";
      billing.city = "Managua";
      billing.phone = "99999999";
      billing.zip = "11001";

      const item = new Item();
      item.code = "00001";
      item.title = "compras Gonlop Parts";
      item.price = this.dataPayment[0].total;
      item.qty = 1;

      const order = new Order();
      order.id = "ORDER-000" + this.dataPayment[0].id;
      order.currency = "NIO";
      order.customer_name = this.dataPayment[0].tar_nombre;
      order.customer_email = this.dataPayment[0].email;
      order.addItem(item);

      const sale = new SaleTransaction();
      sale.setOrder(order);
      sale.setCard(card);
      sale.setBilling(billing);

      const service = new Transaction(settings);

      // with callback
      service
        .doSale(sale)
        .then((response) => {
          //console.log("res ", response);
          if (response.success) {
            this.pay();
            return;
          } else {
            alert("Error, verificar");
          }
          if (TransactionResult.validateResponse(response)) {
            const result = TransactionResult.fromResponse(response);
            //console.log("1");
            const validPayment = service.verifyPaymentHash(
              result.payment_hash,
              order.id,
              "abc..." // secret
            );
            //console.log("2");

            //console.log("--ln225 ", validPayment);
            if (validPayment) {
              //console.log("SUCESS");
              this.pay();
            }
          }
        })
        .catch((error) => {
          //alert("Error, verifique...");
          this.message = error;
          //console.log("error" + error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.payment-form {
  padding-bottom: 50px;
  font-family: "Montserrat", sans-serif;
}

.payment-form.dark {
  background-color: #f6f6f6;
}

.payment-form .content {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: white;
}

.payment-form .block-heading {
  padding-top: 50px;
  margin-bottom: 40px;
  text-align: center;
}

.payment-form .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.payment-form.dark .block-heading p {
  opacity: 0.8;
}

.payment-form .block-heading h1,
.payment-form .block-heading h2,
.payment-form .block-heading h3 {
  margin-bottom: 1.2rem;
  color: #3b99e0;
}

.payment-form form {
  border-top: 2px solid #5ea4f3;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: #ffffff;
  padding: 0;
  max-width: 600px;
  margin: auto;
}

.payment-form .title {
  font-size: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.8em;
  font-weight: 600;
  padding-bottom: 8px;
}

.payment-form .products {
  background-color: #f7fbff;
  padding: 25px;
}

.payment-form .products .item {
  margin-bottom: 1em;
}

.payment-form .products .item-name {
  font-weight: 600;
  font-size: 0.9em;
}

.payment-form .products .item-description {
  font-size: 0.8em;
  opacity: 0.6;
}

.payment-form .products .item p {
  margin-bottom: 0.2em;
}

.payment-form .products .price {
  float: right;
  font-weight: 600;
  font-size: 0.9em;
}

.payment-form .products .total {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding-top: 19px;
  font-weight: 600;
  line-height: 1;
}

.payment-form .card-details {
  padding: 25px 25px 15px;
}

.payment-form .card-details label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #79818a;
  text-transform: uppercase;
}

.payment-form .card-details button {
  margin-top: 0.6em;
  padding: 12px 0;
  font-weight: 600;
}

.payment-form .date-separator {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

@media (min-width: 576px) {
  .payment-form .title {
    font-size: 1.2em;
  }

  .payment-form .products {
    padding: 40px;
  }

  .payment-form .products .item-name {
    font-size: 1em;
  }

  .payment-form .products .price {
    font-size: 1em;
  }

  .payment-form .card-details {
    padding: 40px 40px 30px;
  }

  .payment-form .card-details button {
    margin-top: 2em;
  }
}
</style>
