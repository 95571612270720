<template>
  <!-- <div class="hello">
    <h1>{{ msg }}</h1>
  </div> -->
  <main class="page payment-page">
    <section class="payment-form dark">
      <div class="container">
        <div class="block-heading">
          <h2>Checkout Gonlop parts</h2>
        </div>
        <form >
          <!-- <h3 class="title">Checkout</h3> -->
          <div class="row m-4">
            <div class="col-md-6 mb-4">
              <b><div class="total text-center"><span class="price ml-4">Tu pago se ha realizado correctamente!</span></div></b>
              <b><div class="total text-center"><span class="price ml-4">Ya Puedes regresar a la App de Gonlop Parts.</span></div></b>
              
            </div>
            
          </div>
          
          <!-- <div class="card-details">
            <h3 class="title">Tarjeta de crédito</h3>
            <div class="row">
              <div class="form-group col-sm-7">
                <label for="card-holder">Nombre en tarjeta de crédito</label>
                <input v-model="dataPayment[0].tar_nombre" id="card-holder" type="text" class="form-control" placeholder="Card Holder" aria-label="Card Holder" aria-describedby="basic-addon1" />
              </div>
              <div class="form-group col-sm-5">
                <label for="">vencimiento</label>
                <div class="input-group expiration-date">
                  <input v-model="vencimientoM" type="text" class="form-control text-right" placeholder="MM" aria-label="MM" aria-describedby="basic-addon1" />
                  <span class="date-separator">/</span>
                  <input v-model="vencimientoY" type="text" class="form-control" placeholder="YY" aria-label="YY" aria-describedby="basic-addon1" />
                </div>
              </div>
              <div class="form-group col-sm-8">
                <label for="card-number">Número de Tarjeta</label>
                <div class="row">
                  <div class="col-12">
                    <input id="card-number" v-model="tarjetac" type="text" class="form-control" placeholder="Card Number" aria-label="Card Holder" aria-describedby="basic-addon1" />
                   </div>

                </div>
              </div>
              <div class="form-group col-sm-4">
                <label for="cvc">CVC</label>
                <input id="cvc" type="text" class="form-control" placeholder="CVC" aria-label="Card Holder" aria-describedby="basic-addon1" />
              </div>
              <div class="form-group col-sm-12">
                <button type="button" class="btn btn-primary btn-block">Procesar</button>
              </div>
            </div>
          </div> -->
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import Settings from "@pixelpay/sdk-core/lib/models/Settings";
import Card from "@pixelpay/sdk-core/lib/models/Card";
import Billing from "@pixelpay/sdk-core/lib/models/Billing";
import Item from "@pixelpay/sdk-core/lib/models/Item";
import Order from "@pixelpay/sdk-core/lib/models/Order";
import SaleTransaction from "@pixelpay/sdk-core/lib/requests/SaleTransaction";
import Transaction from "@pixelpay/sdk-core/lib/services/Transaction";
import TransactionResult from "@pixelpay/sdk-core/lib/entities/TransactionResult";

export default {
  props: {
    msg: String,
  },
  data(){
    return{
      id:0,
      codgen:'OmNzs$3XSKQzZEwqudAIxAZexuc8ZzJMQlUQpHg0bZn5fEJORV67F8F',
      dataPayment:{},
      vencimientoM: null,
      vencimientoY: null,
      tarjetac:'',
      tarjetacF:''
    }
  },
  async mounted(){
    setTimeout(() => {
      window.top.close();
    }, 3000);

    //alert(this.id)
  },  
  methods: {
    async generateRandomString(num) {
      // const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz*$!";
      let result1 = "";
      const charactersLength = characters.length;
      for (let i = 0; i < num; i++) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      //console.log(result1)
      this.codgen = result1
      return await result1;
    },
    procesar(){
      //console.log('helou')
      //alert(this.$store.state.count)

    },
    async getDataPayment() {
      let dataPayment = await this.$store.dispatch('get', { path: 'pedidos_clientes/getByCodePayment/'+ this.codgen})
      this.dataPayment = dataPayment
      //console.log('------', this.dataPayment)
      let vencimiento = dataPayment[0].vencimiento
      //console.log('------',vencimiento)
      // this.vencimiento = vencimiento.split(vencimiento, '/')
      this.vencimientoM = vencimiento.substr(0,2)
      this.vencimientoY = vencimiento.substr(3,2)
      this.tarjetac = dataPayment[0].numero//.substr(0,dataPayment[0].numero.length-4)
      //,vencimiento.substr(3,2)
       //console.log('------',this.vencimientoM)
    },

    sale() {
      const settings = new Settings();
      settings.setupEndpoint("https://ni.ficoposonline.com");
      settings.setupCredentials("FN4067011851", "5cddc4f18755a0a575f6fc8c0c8d2181");

      const card = new Card();
      card.number = "";
      card.cvv2 = "";
      card.expire_month = 5;
      card.expire_year = "";
      card.cardholder = "HARVEY PASTORA";

      const billing = new Billing();
      billing.address = "Managua";
      billing.country = "NI";
      billing.state = "NI-MN";
      billing.city = "Managua";
      billing.phone = "99999999";
      billing.zip = "11001";

      const item = new Item();
      item.code = "00001";
      item.title = "Videojuego";
      item.price = 0.1;
      item.qty = 1;

      const order = new Order();
      order.id = "ORDER-888881";
      order.currency = "NIO";
      order.customer_name = "Harvey Pastora";
      order.customer_email = "mecatestudionic@gmail.com";
      order.addItem(item);

      const sale = new SaleTransaction();
      sale.setOrder(order);
      sale.setCard(card);
      sale.setBilling(billing);

      const service = new Transaction(settings);

      // with callback
      service
        .doSale(sale)
        .then((response) => {
          //console.log(response);
          if (TransactionResult.validateResponse(response)) {
            const result = TransactionResult.fromResponse(response);

            const validPayment = service.verifyPaymentHash(
              result.payment_hash,
              order.id,
              "abc..." // secret
            );

            if (validPayment) {
              //console.log("SUCESS");
            }
          }
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.payment-form {
  padding-bottom: 50px;
  font-family: "Montserrat", sans-serif;
}

.payment-form.dark {
  background-color: #f6f6f6;
}

.payment-form .content {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: white;
}

.payment-form .block-heading {
  padding-top: 50px;
  margin-bottom: 40px;
  text-align: center;
}

.payment-form .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.payment-form.dark .block-heading p {
  opacity: 0.8;
}

.payment-form .block-heading h1,
.payment-form .block-heading h2,
.payment-form .block-heading h3 {
  margin-bottom: 1.2rem;
  color: #3b99e0;
}

.payment-form form {
  border-top: 2px solid #5ea4f3;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: #ffffff;
  padding: 0;
  max-width: 600px;
  margin: auto;
}

.payment-form .title {
  font-size: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.8em;
  font-weight: 600;
  padding-bottom: 8px;
}

.payment-form .products {
  background-color: #f7fbff;
  padding: 25px;
}

.payment-form .products .item {
  margin-bottom: 1em;
}

.payment-form .products .item-name {
  font-weight: 600;
  font-size: 0.9em;
}

.payment-form .products .item-description {
  font-size: 0.8em;
  opacity: 0.6;
}

.payment-form .products .item p {
  margin-bottom: 0.2em;
}

.payment-form .products .price {
  float: right;
  font-weight: 600;
  font-size: 0.9em;
}

.payment-form .products .total {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding-top: 19px;
  font-weight: 600;
  line-height: 1;
}

.payment-form .card-details {
  padding: 25px 25px 15px;
}

.payment-form .card-details label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #79818a;
  text-transform: uppercase;
}

.payment-form .card-details button {
  margin-top: 0.6em;
  padding: 12px 0;
  font-weight: 600;
}

.payment-form .date-separator {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

@media (min-width: 576px) {
  .payment-form .title {
    font-size: 1.2em;
  }

  .payment-form .products {
    padding: 40px;
  }

  .payment-form .products .item-name {
    font-size: 1em;
  }

  .payment-form .products .price {
    font-size: 1em;
  }

  .payment-form .card-details {
    padding: 40px 40px 30px;
  }

  .payment-form .card-details button {
    margin-top: 2em;
  }
}
</style>
